<template>
  <div class="message_home_page">
    <div class="message_home_page_cont">
      <p class="message-tit">消息树</p>
    
      <div class="table_page">
          <div class="table_top">
              <div class="title">
                  <p class="title-line" ></p>
                  <p class="title-name">下载文件</p>
              </div>
          </div>
          <div class="message-box">
              <el-table header-align="center"  
                  header-cell-class-name="message-header_row_style" 
                  :data="downList" style="width: 100%;border-radius:6px;"
                  cell-class-name="message-row-style"
              >
                  <el-table-column :align="item.align || 'center'" :show-overflow-tooltip="true" v-for="item in columns" :key="item.prop" :prop="item.prop" :width="item.width" :label="item.label">
                  </el-table-column>
                  <el-table-column align="center" label="操作">
                  <template slot-scope="scope" >
                      <div class="table_btns">
                          <div class="table_btn" @click="down(scope.row)" style="color: #BA3037;opacity: 0.9;">
                              {{ scope.row.isDown ? "已下载" : "下载" }}
                          </div>
                      </div>
                  </template>
                  </el-table-column>
              </el-table>
              <comPagination :params="downParams" @changePage="downChangePage"></comPagination>
          </div>
      </div>
      
      <div class="table_page">
          <div class="table_top">
              <div class="title">
                  <p class="title-line" style="background:#40528F;"></p>
                  <p class="title-name">上传文件</p>
              </div>
              <p type="primary" class="message-upload-btn" @click="alertShow = true">我要上传</p>
          </div>
          <div class="message-box">
              <el-table header-align="center"  
                  header-cell-class-name="upload-file" 
                  :data="uploadList" style="width: 100%;border-radius:6px;"
                  cell-class-name="message-row-style"
              >
                  <el-table-column :align="item.align || 'center'" :show-overflow-tooltip="true" v-for="item in columns" :key="item.prop" :prop="item.prop" :width="item.width" :label="item.label">
                  </el-table-column>
                  <el-table-column align="center" label="操作">
                  <template slot-scope="scope" >
                      <div class="table_btns">
                          <div class="table_btn" @click="update(scope.row)" style="color: #40528F;opacity: 0.9;">更新</div>
                      </div>
                  </template>
                  </el-table-column>
              </el-table>
          
              <comPagination class="page-color" :params="updataParams" @changePage="updataChangePage"></comPagination>
          </div>
      </div>
      <!-- 上传弹窗 -->
      <el-dialog title="上传" center @close="closeAlert" :visible.sync="alertShow" class="my-upload-dialog">
        <el-form :model="form">
          <el-form-item style="margin-bottom:0.2rem" label="文件名称" :label-width="formLabelWidth">
            <el-input style="width:7rem" v-model="form.title" clearable class="form-item-inp"></el-input>
          </el-form-item>
          <el-form-item label="上传文件" style="margin-bottom:0.2rem" :label-width="formLabelWidth">
            <!-- 上传文件 -->
            <el-input style="width:6rem" v-model="form.upload" disabled clearable></el-input>
            <el-upload :headers="{token: $store.state.userInfo.token}" class="upload" :action="constant.UPLOAD_URL + '/file/upload'" :show-file-list="false" :on-success="uploadSuccess" :on-progress="onProgress"
              :before-upload="beforeImgUpload"
              ref="uploadImg"
            >
              <el-button class="my-upload-dialog-btn" type="primary">点击上传</el-button>
            </el-upload>
            <el-progress v-show="filePercent" :percentage="filePercent" style="width:7rem;margin:0.1rem 0"></el-progress>
          </el-form-item>
          <el-form-item v-if="form.isVideo" label="上传视频" style="margin-bottom:0.2rem" :label-width="formLabelWidth">
            <!--  -->
            <el-input style="width:6rem" v-model="form.videoUpload" disabled clearable></el-input>
            <el-upload :headers="{token: $store.state.userInfo.token}" class="upload" :action="constant.UPLOAD_URL + '/vod/video/uploadAlyiVideo'" :show-file-list="false" :on-success="uploadVideoSuccess" :on-progress="onVideoProgress"
              :before-upload="beforeVideoUpload"
              ref="uploadVideo"
            >
              <el-button class="my-upload-dialog-btn" type="primary">点击上传</el-button>
            </el-upload>
            <el-progress v-show="videoPercent" :percentage="videoPercent" style="width:7rem;margin:0.1rem 0"></el-progress>
          </el-form-item>
          <el-form-item label="是否上传视频" :label-width="formLabelWidth">
            <!-- <el-radio-group v-model="form.isVideo" @change="changeIsVideo">
              <el-radio :label="0">否</el-radio>
              <el-radio :label="1">是</el-radio>
            </el-radio-group> -->
            <el-switch
              v-model="form.isVideo"
              active-text=""
              inactive-text=""
              @change="changeIsVideo">
            </el-switch>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button class="my-upload-dialog-foot-btn active" type="primary" @click="submit">确 定</el-button>
          <el-button class="my-upload-dialog-foot-btn" @click="closeAlert">取 消</el-button>
        </div>
      </el-dialog>
    </div>
    

  </div>
</template>

<script>
//   import myTable from "@/components/admin/table.vue";
  import comPagination from "@/components/admin/pagination.vue";
  export default {
    name: "letter",
    components: {
      comPagination,
    },
    data() {
      return {
        //班级列表
        columns: [
        {
          prop: "title",
          label: "名称",
          width: 500
        },
        {
          prop: "createtime",
          label: "时间",
        }, ],
        downList: [],
        uploadList: [],
        //分页
        downParams: {
          page: 0,
          size: 10,
          total: 0,
        },
        updataParams: {
          page: 0,
          size: 10,
          total: 0,
        },

        form: {
          title: '',
          upload: '',
          isVideo: false,
          videoUpload: ''
        },
        filePercent: 0,
        videoPercent: 0,
        formLabelWidth: '1.2rem',

        alertShow: false,
        isUpdate: false, //上传或更新
        tabUplad:{}
      };
    },
    created() {},
    mounted() {
      this.init();
    },
    methods: {
      //初始化
      init() {
        this.getDownInfo();
        this.getUploadInfo();
      },
      getDownInfo() {
        let data = {
          schoolId: this.$store.state.userInfo.schoolId,
        };
        this.api.admin.findDocDownList(this.downParams, data).then((res) => {
          this.downParams.total = res.data.total;
          this.downList = res.data.list.map((item) => {
            let data = { ...item.doc, isDown: item.isDown };
            data.createtime = this.common.dateFormat(data.createtime * 1000);
            return data;
          });
        });
      },
      getUploadInfo() {
        let data = {
          schoolId: this.$store.state.userInfo.schoolId,
        };
        this.api.admin.findDocUploadList(this.updataParams, data).then((res) => {
          this.updataParams.total = res.data.total;
          this.uploadList = res.data.list.map((item) => {
            let data = { ...item };
            data.createtime = this.common.dateFormat(data.createtime * 1000);
            data.normalUrl = data.normalUrl === ' ' ? '' : data.normalUrl;
            return data;
          });
        });
      },
      //更新
      update(val) {
        this.alertShow = true
        this.isUpdate = true
        this.tabUplad = val
        this.form.title = val.title
        this.form.upload = val.attachfile
        this.form.videoUpload = val.normalUrl
        if(val.normalUrl){
          this.form.isVideo = true
        }
      },
      //下载
      down(val) {
        let data = {
          fid:val.id
        };
        this.api.admin.docHistoryAdd(data).then((res) => {
          console.log(res)
        });
        let a = document.createElement('a')
        a.href = this.constant.URL + val.attachfile
        a.click()
        val.isDown = true
      },
      // 上传成功
      uploadSuccess(res) {
        this.form.upload = res.data.filename
      },
      uploadVideoSuccess(res) {
        this.form.videoUpload = res.data;
        this.videoPercent = 100;
        console.log(this.videoPercent,'this.videoPercent')
        if (this.videoPercent == 100) {
          this.videoPercent = 0
        }
      },
      //上传进度
      onProgress(event, file) {
        this.filePercent = parseInt(file.percentage)
        if (this.filePercent == 100) {
          this.filePercent = 0
        }
      },
      onVideoProgress(event, file) {
        this.videoPercent = parseInt(file.percentage)
        if (this.videoPercent == 100) {
          this.videoPercent = 0
        }
      },
      //分页 todo 待测试
      downChangePage(val) {
        this.downParams.page = val;
        this.getDownInfo();
      },
      updataChangePage(val) {
        this.updataParams.page = val;
        this.getUploadInfo();
      },
      // 关闭弹窗
      closeAlert() {
        this.alertShow = false
        this.form = {
          title: '',
          upload: '',
          isVideo: false,
          videoUpload: ''
        }
        this.filePercent = 0
        this.videoPercent = 0
        this.isUpdate = false
      },
      //提交上传
      submit() {
        if (!this.form.title.trim()) {
          this.$notice({
            message: '请输入文件名'
          }).isShow()
          return;
        }else{
          if(!this.common.validateLength(this.form.title.trim(),50)){
            this.$notice({
              message: '请输入正确格式文件名'
            }).isShow()
            return;
          }
        }
        if (!this.form.upload && !this.form.videoUpload) {
          this.$notice({
            message: '请上传文件或视频'
          }).isShow()
          return;
        }
        // true 上传 false 更新
        let data = {
          title: this.form.title,
          attachfile: this.form.upload,
          normalUrl: this.form.videoUpload
        }
        if (!this.isUpdate) {
          this.api.admin.uploadDoc(data).then(res => {
            if (res.flag) {
              this.$notice({
                type: 'success',
                message: res.message
              }).isShow()
              this.closeAlert();
              this.getUploadInfo();
            } else {
                              this.$notice({
                  message: res.message
                }).isShow()
            }
          })
        }else{
          this.api.admin.doc(data,this.tabUplad.id).then(res => {
            if (res.flag) {
              this.$notice({
                type: 'success',
                message: res.message
              }).isShow()
              this.closeAlert();
              this.getUploadInfo();
            } else {
                this.$notice({
                  message: res.message
                }).isShow()
            }
          })
        }
      },
      //切换是否上传视频
      changeIsVideo(val){
        console.log(val,'dddd')
        if(val){
          this.form.videoUpload = '';
        }
      },
      //校验图片大小
      beforeImgUpload(file){
        //限制上传图片处理函数
        const isLt50M = file.size / 1024 / 1024 < 80;
        if (!isLt50M) {
          this.$message.error("上传图片大小不能超过 80MB!");
          // this.$refs.uploadImg.clearFiles();
          return false;
        }
      },
      beforeVideoUpload(file){
        //限制上传视频处理函数
        const isLt50M = file.size / 1024 / 1024 < 80;
        if (!isLt50M) {
          this.$message.error("上传视频大小不能超过 80MB!");
          // this.$refs.uploadVideo.clearFiles();
          return false;
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .message_home_page {
    background: #F1F1F1;
    .message_home_page_cont{
      width:1354px;
      margin:0 auto;
      padding:30px 40px;
      background: #FFFFFF;
    }
    .upload {
      display: inline-block;
      margin-left: 0.1rem;
    }
    .message-tit{
        margin-bottom:20px;
        font-size: 24px;
        font-weight: bold;
        color: #BA3037;
    }
    .table_top {
        @include flex(row,space-between,center);
        .title{
            margin-bottom:24px;
            font-size: 22px;
            font-weight: bold;
            color: #333333;
            overflow: hidden;
            .title-line{
                margin-right:12px;
                margin-top:3px;
                float: left;
                width: 4px;
                height: 18px;
                background: #BA3037;
                border-radius: 2px;
            }
            .title-name{
                margin-top: -2px;
                float: left;
            }
        }
        .message-upload-btn{
            margin-bottom: 4px;
            display: block;
            width:112px;
            height: 56px;
            line-height: 56px;
            text-align: center;
            font-size: 0.24rem;
            background: url('../../assets/img/message/Btn_Bg_Upload_U.png') no-repeat;
            background-size: cover;
            font-size: 18px;
            font-weight: normal;
            color: #FFFFFF;
            cursor:pointer;
        }
        .message-upload-btn:hover{
            background: url('../../assets/img/message/Btn_Bg_Upload_H.png') no-repeat;
            background-size: cover;
        }
    }
    .message-box{
        margin-bottom:30px;
        box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
        border-radius: 4px;

        .dialog_block{
            padding: 14px 0;
            text-align: center;
        }
    }
    .table_btn{
        border:none;
        font-size:14px;
        font-weight: 400;
        opacity: 0.9;
    }
  }
</style>
<style lang="scss">
.message-header_row_style{
    background:#BA3037 !important;
    color:#FFFFFF;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    opacity: 0.9;
}
.upload-file{
    background:#40528F !important;
    color:#FFFFFF;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    opacity: 0.9;
}
.message-row-style{
    border-bottom:1px solid #DDDDDD !important;
}
.page-color .el-pagination.is-background .el-pager li:not(.disabled).active
{
    background-color: #6E82CF;
}
.my-upload-dialog{
  .el-dialog{
    border-radius: 10px 10px 0 0;
  }
  .el-dialog__header{
    padding-bottom:20px;
    background: #CB1B32;
    border-radius: 10px 10px 0 0;
    font-size: 18px;
    font-weight: bold;
    
    .el-dialog__title{
      color: #FFFFFF;
    }
    .el-dialog__close{
      color:#FFFFFF;
      font-weight:bold;
      font-size:18px;
    }
  }
  .my-upload-dialog-btn{
    width: 100px;
    height: 38px;
    text-align: center;
    line-height: 38px;
    border:none;
    background: linear-gradient(232deg, #CB1930, #FF4054);
    box-shadow: 0px 3px 6px 0px rgba(255, 34, 67, 0.33);
    border-radius: 6px;
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
  }
  .el-switch.is-checked .el-switch__core{
    border-color: #CB1B32;
    background-color: #CB1B32;
  }
  .my-upload-dialog-foot-btn{
    margin-bottom:10px;
    width: 100px;
    height: 38px;
    border: 1px solid #CC1930;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 400;
    color: #CC1930;
  }
  .active{
    margin-right:50px;
    color: #FFFFFF;
    background: linear-gradient(232deg, #CB1930, #FF4054);
    box-shadow: 0px 3px 6px 0px rgba(255, 34, 67, 0.33);
  }
  .my-upload-dialog-btn:hover,
  .active:hover{
    border:none;
    background: linear-gradient(232deg, rgba(185, 48, 55, 0.8), rgba(239, 82, 91, 0.8));
    box-shadow: 0px 3px 6px 0px rgba(255, 67, 77, 0.33);
  }
}
</style>